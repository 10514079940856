<template>
  <div class="box">
    <div class="box2">
      <div class="menu-tool">
        <div class="menu-tool-tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部(12)" name="first"></el-tab-pane>
            <el-tab-pane label="处理中(7)" name="second"></el-tab-pane>
            <el-tab-pane label="待审批(2)" name="third"></el-tab-pane>
            <el-tab-pane label="已审批(3)" name="fourth"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="menu-tool-right">
          <!-- <div class="menu-tool-right-toogle">
            <el-button size="mini" type="primary" plain>卡片模式</el-button>
            <el-button size="mini" plain>列表模式</el-button>
          </div> -->
          <div class="menu-tool-right-input">
            <el-input v-model="search" size="mini" placeholder="请输入工单编号"></el-input>
          </div>
        </div>
      </div>
      <div>
        <!-- <div class="card-item" v-for="item in list" :key="item" @click="toDetail(item)">
          <div class="card-item-title">
            <div class="card-item-title-left">
              <i class="iconfont icon-guanwang icon"></i>
              <div class="card-item-title-text">
                <div class="title">东环路1号管网</div>
                <div class="subtitle">定边县</div>
              </div>
            </div>
            <div class="card-item-title-collect"><i class="iconfont icon-guanwang icon"></i></div>
          </div>
          <div class="card-item-content">
            <div class="warning-line"></div>
            <div :id="'card-item-chart'+item" style="flex: 1;width: 30%;height: 150px;"></div>
            <div class="card-item-chart-info">
              <div class="info-item-data">
                <span>流量： </span>
                <span class="data">65m³/h</span>
              </div>
              <div class="info-item-line"></div>
              <div class="info-item-time">2024-03-20 18:00:00</div>
            </div>
          </div>
        </div> -->
        <div class="table-list">
          <el-table :data="tableData" style="width: 100%" size="small">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="workOrderNumber" label="工单编号"></el-table-column>
            <el-table-column prop="workOrderStatus" label="工单状态">
              <template slot-scope="scope">
                <el-tag size="mini" v-if="scope.row.workOrderStatus === '待审批'" type="warning">待审批</el-tag>
                <el-tag size="mini" v-if="scope.row.workOrderStatus === '处理中'" type="info">处理中</el-tag>
                <el-tag size="mini" v-if="scope.row.workOrderStatus === '已审批'" type="success">已审批</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="priority" label="优先级">
              <template slot-scope="scope">
                <span size="mini" v-if="scope.row.priority === '一般'" style="color: #FFCC48;">一般</span>
                <span size="mini" v-if="scope.row.priority === '重要'" style="color: #FFAD4C;">重要</span>
                <span size="mini" v-if="scope.row.priority === '紧急'" style="color: #FE8426;">紧急</span>
              </template>
            </el-table-column>
            <el-table-column prop="workOrderType" label="工单类型"></el-table-column>
            <el-table-column prop="faultDescription" label="故障描述"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column prop="apporTime" label="审批时间"></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-dropdown placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-link">
                      操作<i class="el-icon-arrow-down"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
export default {
  data() {
    return {
      list: [1,2,3,4,5,6,7,8,9,10],
      activeName: 'first',
      search: '',
      tableData: [
        {
          workOrderNumber: '20230725163925',
          workOrderStatus: '已审批',
          priority: '紧急',
          workOrderType: '管网漏损',
          faultDescription: '2号管网发生漏损',
          createTime: '2023-06-27 18:21:50',
          apporTime: '2023-06-30 08:21:50',
        },
        {
          workOrderNumber: '20230625182121',
          workOrderStatus: '已审批',
          priority: '重要',
          workOrderType: '设备保养',
          faultDescription: '1#泵需要进行维护',
          createTime: '2023-06-25 18:21:50',
          apporTime: '2023-06-26 08:21:50',
        },
        {
          workOrderNumber: '20230623164202',
          workOrderStatus: '处理中',
          priority: '一般',
          workOrderType: '隐患排查',
          faultDescription: '水源1号取水泵存在异响',
          createTime: '2023-06-23 16:42:02',
          apporTime: '2023-06-23 18:24:32',
        },
        {
          workOrderNumber: '20230622144002',
          workOrderStatus: '已审批',
          priority: '重要',
          workOrderType: '设备故障',
          faultDescription: '2号水源井1号泵故障',
          createTime: '2023-06-22 14:40:02',
          apporTime: '2023-06-22 15:10:22',
        },
        {
          workOrderNumber: '20230619111202',
          workOrderStatus: '处理中',
          priority: '一般',
          workOrderType: '隐患排查',
          faultDescription: '1号管网对应井盖松动',
          createTime: '2023-06-19 11:12:02',
          apporTime: '2023-06-19 14:15:52',
        },
        {
          workOrderNumber: '20230617162726',
          workOrderStatus: '待审批',
          priority: '紧急',
          workOrderType: '设备故障',
          faultDescription: '1号水源井1号泵故障',
          createTime: '2023-06-17 16:34:31',
          apporTime: '2023-06-17 17:24:51',
        },
        {
          workOrderNumber: '20230616085002',
          workOrderStatus: '处理中',
          priority: '重要',
          workOrderType: '设备故障',
          faultDescription: '3号管网发生漏损',
          createTime: '2023-06-16 08:50:02',
          apporTime: '2023-06-16 09:20:32',
        },
        {
          workOrderNumber: '20230615142502',
          workOrderStatus: '处理中',
          priority: '紧急',
          workOrderType: '管网漏损',
          faultDescription: '2号管网发生漏损',
          createTime: '2023-06-15 14:25:02',
          apporTime: '2023-06-15 16:22:52',
        },
        {
          workOrderNumber: '20230613162502',
          workOrderStatus: '处理中',
          priority: '重要',
          workOrderType: '隐患排查',
          faultDescription: '3号加压泵存在异响,',
          createTime: '2023-06-13 16:25:02',
          apporTime: '2023-06-13 18:12:03',
        },
        {
          workOrderNumber: '20230725163925',
          workOrderStatus: '待审批',
          priority: '紧急',
          workOrderType: '管网漏损',
          faultDescription: '2号管网发生漏损',
          createTime: '2023-06-12 15:26:30',
          apporTime: '2023-06-12 16:12:40',
        }
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    toDetail(item) {
      this.$router.push({ name: 'overviewPipelineNetworkDetail', params: {row: item}})
    },
    details(row) {

    },
    setChart(v) {
      var myChart = echarts.init(document.getElementById('card-item-chart'+v));
      var option = {
        title: {
          subtext:'Mpa'
        },
        backgroundColor: '',
        color: ['#60EEFF'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter:function(params) {
            return params[0].name +'<br>'+params[0].seriesName+': '+params[0].data+'(m³/h)'
          },
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          data: ['流量'],
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: '流量',
            type: 'bar',
            data: [0.5,0.8,0.3,1],
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            barWidth: 20,
            // label: {
            //   show: true,
            //   position: 'right',
            //   valueAnimation: true
            // },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 0, color: '#79A1E2' },
                { offset: 1, color: '#48B9DA' }
              ]),
              borderRadius: [4,4,0,0],
            },
          }
        ],
        legend: {
          show: false
        }
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.list.forEach(v=>{
      this.setChart(v)
    })
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #202020;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
.box {
  background: #FAFAFA;
  .box2 {
    border-radius: 10px;
    background-color: #FFF;
  }
}
.menu-tool {
  border-radius: 10px;
  background-color: #FFF;
  border-bottom: 14px solid #FAFAFA;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .menu-tool-tabs {
    width: 75%;
  }
  .menu-tool-right {
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-tool-right-toogle {
      margin-right: 30px;
    }
    .menu-tool-right-input {

    }
  }
}
.wrapper-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  background: #f0f0f0;
  border-radius: 10px;
  .card-item {
    width: 19.3%;
    height: 220px;
    background: #FFF;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    box-sizing: border-box;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      background: #ffffff65;
    }
    .card-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-item-title-left {
        display: flex;
        align-items: center;
        .icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          background-color: #D9F8E2;
          color: #32D965;
          margin-right: 10px;
        }
        .card-item-title-text {
          .title {
            font-size: 15px;
            font-weight: bold;
          }
          .subtitle {
            font-size: 12px;
            color: #999;
          }
        }
      }
      .card-item-title-collect {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        background-color: #EBEBEB;
        color: #A0A0A0;
      }
    }
    .card-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .warning-line {
        width: 60px;
        height: 2px;
        border-top: 2px dashed red;
        position: absolute;
        bottom: 80px;
        left: 25px;
        z-index: 1;
      }
      .card-item-chart-info {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
        .info-item-data {
          .data {
            color: #00A0EA;
            font-weight: bold;
          }
        }
        .info-item-line {
          width: 100%;
          height: 1px;
          background-color: #A0A0A0;
          margin: 30px 0;
        }
      }
    }
  }
}
</style>