<template>
    <div class="box">
      <box1 />
      <box2 />
    </div>
  </template>
  
  <script>
  import box1 from './box1'
  import box2 from './box2'
  export default {
    components: {
      box1,
      box2,
    },
    data() {
      return {
  
      }
    },
    methods: {
  
    },
    mounted() {
  
    }
  }
  </script>
  
  <style lang="less" scoped>
  .box {
    padding: 0 !important;
  }
  </style>