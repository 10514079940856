<template>
  <div class="box">
    <div class="box2">
      <div class="box-content">
        <div class="statistics">
          <div class="statistics-item" v-for="(item,key) in statisticsList" :key="key">
            <div class="statistics-item-left">
              <span class="iconfont icon-dingdan icon"></span>
              <span class="label">{{ item.name }}</span>
            </div>
            <div class="statistics-item-right">{{ item.data }} 个</div>
          </div>
        </div>
        <div class="online-statistics">
          <div class="title">近七日工单分布</div>
          <div class="online-chart-wrapper">
            <div class="online-content">
              <div id="online-statistics-chart"></div>
            </div>
            <div class="online-content">
              <div id="online-statistics-chart2"></div>
            </div>
            <div class="online-content">
              <div id="online-statistics-chart3"></div>
            </div>
          </div>
        </div>
        <div class="analysis">
          <div class="analysis-title">
            <div class="title">月工单统计</div>
            <div class="select">
              <el-select style="width:80%;" size="mini" v-model="form.fluctuationAnalysis" clearable placeholder="请选择">
                <el-option
                  v-for="item in fluctuationAnalysiss"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div id="analysis-chart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
export default {
  data() {
    return {
      statisticsList: [
        { name: '今日工单总数', data: 12},
        { name: '今日处理中', data: 12},
        { name: '今日待审批', data: 0},
        { name: '今日已审批', data: 1},
      ],
      form: {
        fluctuationAnalysis: 1
      },
      fluctuationAnalysiss: [
        {id: 1,name: '东环路1号管网'}
      ]
    };
  },
  methods: {
    setAnalysisChart() {
      var myChart = echarts.init(document.getElementById('analysis-chart'));
      var option = {
        title: {
          subtext: '单位: %'
        },
        backgroundColor: '',
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          y: 'top',
          data: ['完成率']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '完成率',
            type: 'line',
            stack: 'Total',
            areaStyle: {
              opacity: 0.1
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: '#9690FA',
              shadowColor: '#9690FA',
            },
            lineStyle: {
              shadowColor: '#9690FA',
              shadowBlur: 20,
              color: {
                type: 'linear',
                colorStops: [
                  {offset:0,color:'#9690FA'},
                  {offset:1,color:'#9690FA'},
                ]
              }
            },
            smooth: true,
            data: [19,25,24,26,42,47,34,63,16,43,73,63,89]
          }
        ],
      };
      myChart.setOption(option);
    },
    setOnlineStatisticsChart() {
      var myChart = echarts.init(document.getElementById('online-statistics-chart'));
      var option = {
        title: {
          subtext: ''
        },
        backgroundColor: '',
        tooltip: {
          trigger: "item"
        },
        legend: {
          y: 'top',
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: '在线统计',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2,
              color: function(params) {
                var colorList = ['#2FADFB']
                return colorList[params.dataIndex]
              }
            },
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#4c4a4a',
                formatter: '{total|' + '已审批' +'}'+'\n\r'+'{active|状态}',
                rich: {
                  total: {
                    fontSize: 20,
                    fontFamily: 'Microsoft YaHei',
                    color: '#2FADFB',
                    fontWeight: 'bold'
                  },
                  active: {
                    fontSize: 12,
                    fontFamily: 'Microsoft YaHei',
                    color: '#6c7a89',
                    lineHeight: 20
                  }
                }
              },
              emphasis: {
                show: true,
              }
            },
            emphasis: {
              itemStyle: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold',
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 100, name: '数据' },
            ]
          }
        ],
      };
      myChart.setOption(option);
    },
    setOnlineStatisticsChart2() {
      var myChart = echarts.init(document.getElementById('online-statistics-chart2'));
      var option = {
        title: {
          subtext: ''
        },
        backgroundColor: '',
        tooltip: {
          trigger: "item"
        },
        legend: {
          y: 'top',
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: '在线统计',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2,
              color: function(params) {
                var colorList = ['#2FADFB']
                return colorList[params.dataIndex]
              }
            },
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#4c4a4a',
                formatter: '{total|' + '一般' +'}'+'\n\r'+'{active|优先级}',
                rich: {
                  total: {
                    fontSize: 20,
                    fontFamily: 'Microsoft YaHei',
                    color: '#2FADFB',
                    fontWeight: 'bold'
                  },
                  active: {
                    fontSize: 12,
                    fontFamily: 'Microsoft YaHei',
                    color: '#6c7a89',
                    lineHeight: 20
                  }
                }
              },
              emphasis: {
                show: true,
              }
            },
            emphasis: {
              itemStyle: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold',
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 100, name: '数据' },
            ]
          }
        ],
      };
      myChart.setOption(option);
    },
    setOnlineStatisticsChart3() {
      var myChart = echarts.init(document.getElementById('online-statistics-chart3'));
      var option = {
        title: {
          subtext: ''
        },
        backgroundColor: '',
        tooltip: {
          trigger: "item"
        },
        legend: {
          y: 'top',
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: '在线统计',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2,
              color: function(params) {
                var colorList = ['#2FADFB']
                return colorList[params.dataIndex]
              }
            },
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#4c4a4a',
                formatter: '{total|' + '设备正常' +'}'+'\n\r'+'{active|类型}',
                rich: {
                  total: {
                    fontSize: 20,
                    fontFamily: 'Microsoft YaHei',
                    color: '#2FADFB',
                    fontWeight: 'bold'
                  },
                  active: {
                    fontSize: 12,
                    fontFamily: 'Microsoft YaHei',
                    color: '#6c7a89',
                    lineHeight: 20
                  }
                }
              },
              emphasis: {
                show: true,
              }
            },
            emphasis: {
              itemStyle: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold',
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 100, name: '数据' },
            ]
          }
        ],
      };
      myChart.setOption(option);
    }
  },
  mounted() {
    this.setAnalysisChart()
    this.setOnlineStatisticsChart()
    this.setOnlineStatisticsChart2()
    this.setOnlineStatisticsChart3()
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #202020;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
.box {
  border-bottom: 14px solid #FAFAFA;
  background: #FAFAFA;
  .box2 {
    border-radius: 10px;
    background: #FFF;
  }
  .content {
    padding: 0 16px;
    padding-bottom: 16px;
  }
}
.box-content {
  display: flex;
  padding: 20px;
  font-size: 14px;
  .title { 
    color: #202020;
    font-size: 17px;
    padding: 0 16px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    position: relative;
  }
  .statistics {
    width: 15%;
    margin-right: 30px;
    .statistics-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .statistics-item-left {
        display: flex;
        align-items: center;
        .icon {
          border: 2px solid #009CFB;
          color: #009CFB;
          border-radius: 50%;
          padding: 5px;
          font-size: 20px;
          margin-right: 10px;
        }
      }
      .statistics-item-right {
        font-size: 16px;
        font-weight: bold;
        color: #009CFB;
      }
    }
  }
  .analysis {
    width: 30%;
    margin-right: 30px;
    .analysis-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .select {
        color: #009CFB;
        text-align: right;
      }
    }
    #analysis-chart {
      height: 200px;
    }
  }
  .online-statistics {
    width: 55%;
    margin-right: 30px;
    .online-chart-wrapper {
      display: flex;
      .online-content {
        width: 33%; 
      }
    }
    #online-statistics-chart {
      height: 200px;
    }
    #online-statistics-chart2 {
      height: 200px;
    }
    #online-statistics-chart3 {
      height: 200px;
    }
  }
}
</style>